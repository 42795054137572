import React from 'react';
import { Col, Container, Row } from "reactstrap";
import PageTitle from '../../components/PageTitle';
import Topbar from '../Layout/topbar/Topbar';

const Terms = (props: any) => {

    return (
        <>
            <Topbar />

            <div className={'acco-terms'}>

                <PageTitle title='Terms of Use'/>
                <Container>
                    <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                            <p><b>Last Modified: March, 2020</b></p>

                            <h3>Acceptance of the Terms of Use</h3>

                            <p>These terms of use are entered into by and between the customer (“<b>You</b>” or “<b>Your</b>”) and
                                Action Driven Education, LLC (“<b>Company</b>”, “<b>We</b>”, “<b>Us</b>” or “<b>Our</b>”). The following terms and
                                conditions, together with any documents they expressly incorporate by reference
                                (collectively, these “<b>Terms of Use</b>”), govern Your access to and use of www.accomods.com
                                (the “Site”), including, without limitation (hereafter “including”), any content,
                                functionality and services offered on or through <a href="http://www.actiondriveneducation.com" target="_blank">www.actiondriveneducation.com</a>
                                (collectively, the “<b>Service</b>”), whether as a guest or a registered user.</p>

                            <p>Please read the Terms of Use carefully before You start to use the Service. By using the
                                Service and intending to be legally bound hereby, You accept and agree to be bound and
                                abide by these Terms of Use and Our Privacy Policy, found at
                                <a href="https://actiondriveneducation.com/wp-content/uploads/2020/03/Action-Driven-Education-LLC-Privacy-Policy.docx" target="_blank">https://actiondriveneducation.com/wp-content/uploads/2020/03/Action-Driven-Education-LLC-Privacy-Policy.docx</a>
                                incorporated herein by reference. If You do not want to agree to these Terms of Use or
                                the Privacy Policy, You must not access or use the Service.</p>

                            <p>The Service is offered and available to users who are 18 years of age or older. By using
                                the Service, You represent and warrant that You are of legal age to form a binding
                                contract with the Company and meet all of the foregoing eligibility requirements. If You
                                do not meet all of these requirements, You must not access or use the Service.</p>


                            <h3>Changes to the Terms of Use</h3>

                            <p>We grant You a personal, limited, non-transferable, non-exclusive license to access and
                                use the Site. We may revise and update these Terms of Use from time to time in Our sole
                                discretion. All changes are effective immediately when We post them and apply to all
                                access to and use of the Service thereafter. However, any changes to the dispute
                                resolution provisions set forth in Governing Law and Jurisdiction will not apply to any
                                disputes for which the parties have actual notice prior to the date the change is posted
                                on the Service.</p>

                            <p>Your continued use of the Service following the posting of revised Terms of Use means
                                that You accept and agree to the changes. You are expected to check this page frequently
                                so You are aware of any changes, as they are binding on You.</p>


                            <h3>Accessing the Service and Account Security</h3>

                            <p>We reserve the right to withdraw or amend the Service, and any service or material We
                                provide on the Service, in Our sole discretion without notice. We will not be liable if,
                                for any reason, all or any part of the Service is unavailable at any time or for any
                                period. From time to time, We may restrict access to some parts of the Service, or the
                                entire Service, to users, including registered users.</p>

                            <p>You are responsible for:</p>

                            <ul>
                                <li>Making all arrangements necessary for You to have access to the Service; and</li>
                                <li>Ensuring that all persons who access the Service through Your internet connection
                                    are aware of these Terms of Use and comply with them.
                                </li>
                            </ul>

                            <p>To access the Service or some of the resources it offers, You may be asked to provide
                                certain registration details or other information. It is a condition of Your use of the
                                Service that all the information You provide on the Service is correct, current and
                                complete. You agree that all information You provide to register with the Service or
                                otherwise, including, but not limited to, the use of any interactive features on the
                                Service, is governed by Our Privacy Policy,
                                <a href="https://actiondriveneducation.com/wp-content/uploads/2020/03/Action-Driven-Education-LLC-Privacy-Policy.docx" target="_blank">https://actiondriveneducation.com/wp-content/uploads/2020/03/Action-Driven-Education-LLC-Privacy-Policy.docx</a>
                                and You consent to all actions We take caution with respect to Your information,
                                consistent with Our Privacy Policy.</p>

                            <p>If You choose, or are provided with, a user name, password, or any other piece of
                                information as part of Our security procedures, You must treat such information as
                                confidential, and You must not disclose it to any other person or entity. Your account
                                is personal to You, and You must not provide any other person with access to the Service
                                or portions of it using Your user name, password, or other security information. You
                                must notify Us immediately of any unauthorized access to or use of Your user name or
                                password or any other breach of security. You must also ensure that You exit from Your
                                account at the end of each session. You will use particular caution when accessing Your
                                account from a public or shared computer so that others are not able to view or record
                                Your password or other personal information.</p>

                            <p>We have the right to disable any user name, password, or other identifier, whether chosen
                                by You or provided by Us, at any time in Our sole discretion for any or no reason,
                                including if, in Our opinion, You have violated any provision of these Terms of Use.</p>


                            <h3>Intellectual Property Rights</h3>

                            <p>The Service and its entire contents, features, and functionality (including all
                                information, software, text, displays, images, video and audio, and the design,
                                selection, and arrangement thereof), are owned by the Company, its licensors or other
                                providers of such material and are protected by United States and international
                                copyright, trademark, patent, trade secret, and other intellectual property or
                                proprietary rights laws.</p>

                            <p>These Terms of Use permit You to use the Service for Your personal, non-commercial use
                                only. You must not reproduce, distribute, modify, create derivative works of, publicly
                                display, publicly perform, republish, download, store, or transmit any of the material
                                on Our Website, except as follows:</p>


                            <ul>
                                <li>Your computer may temporarily store copies of such materials in RAM incidental to
                                    Your accessing and viewing those materials;
                                </li>
                                <li>You may store files that are automatically cached by Your Web browser for display
                                    enhancement purposes;
                                </li>
                                <li>You may print or download one copy of a reasonable number of pages of the Service
                                    for Your own personal, non-commercial use and not for further reproduction, publication,
                                    or distribution except to provide copies to others also working as a team to educate a
                                    student with whom you’re working to serve;
                                </li>
                                <li>If We provide desktop, mobile or other applications for download, You may download a
                                    single copy to Your computer or mobile device solely for Your own personal,
                                    non-commercial use, provided that any such downloading will acknowledge Your
                                    agreement to be bound by Our end user license agreement for such applications; and
                                </li>
                                <li>If We provide social media features with certain content, You may take such actions
                                    as are enabled by such features.
                                </li>
                            </ul>

                            <p>You must not:</p>


                            <ul>
                                <li>Modify copies of any materials from this site;</li>
                                <li>Use any illustrations, photographs, video or audio sequences, or any graphics
                                    separately from the accompanying text; and
                                </li>
                                <li>Delete or alter any copyright, trademark, or other proprietary rights notices from
                                    copies of materials from this site.
                                </li>
                                <li>You must not access or use for any commercial purposes any part of the Service or
                                    any services or materials available through the Service.
                                </li>
                            </ul>

                            <p>If You print, copy, modify, download, or otherwise use or provide any other person with
                                access to any part of the Service in breach of the Terms of Use, Your right to use the
                                Service will cease immediately and You must, at Our option, return or destroy any copies
                                of the materials You have produced. No right, title, or interest in or to the Service or
                                any content on the Service is transferred to You, and all rights not expressly granted
                                are reserved by the Company. Any use of the Service not expressly permitted by these
                                Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and
                                other laws.</p>


                            <h3>Trademarks</h3>

                            <p>The Company name, the Company logo, and all related names, logos, product and service
                                names, designs, and slogans are trademarks of the Company or its affiliates or
                                licensors. You must not use such marks without the prior written permission of the
                                Company. All other names, logos, product and service names, designs, and slogans on the
                                Service are the trademarks of their respective owners.</p>

                            <h3>Prohibited Uses</h3>
                            
                            <p>You may use the Service only for lawful purposes and in accordance with these Terms of
                                Use. You must not use the Service:</p>


                            <ul>
                                <li>In any way that violates any applicable federal, state, local, or international law
                                    or regulation (including any laws regarding the export of data or software to and from
                                    the US or other countries);
                                </li>

                                <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in
                                    any way by exposing them to inappropriate content, asking for personally identifiable
                                    information or otherwise;
                                </li>

                                <li>To send, knowingly receive, upload, download, use, or re-use any material that does
                                    not comply with the Content Standards set out in these Terms of Use;
                                </li>

                                <li>To transmit, or procure the sending of, any advertising or promotional material,
                                    including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation;
                                </li>

                                <li>To impersonate or attempt to impersonate the Company, a Company employee, another
                                    user, or any other person or entity (including by using e-mail addresses or screen names
                                    associated with any of the foregoing); and
                                </li>

                                <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment
                                    of the Service, or that, as determined by Us, may harm the Company or users of the
                                    Service or expose them to liability.
                                </li>
                            </ul>


                            <p>Additionally, You must not:</p>


                            <ul>
                                <li>Use the Service in any manner that could disable, overburden, damage, or impair the
                                    site or interfere with any other party’s use of the Service, including the ability to
                                    engage in real time activities through the Service;
                                </li>
                                <li>Use any robot, spider or other automatic device, process, or means to access the
                                    Service for any purpose, including monitoring or copying any of the material on the Service;
                                </li>
                                <li>Use any manual process to monitor or copy any of the material on the Service or for
                                    any other unauthorized purpose without Our prior written consent;
                                </li>
                                <li>Use any device, software, or routine that interferes with the proper working of the
                                    Service;
                                </li>
                                <li>Introduce any viruses, trojan horses, worms, logic bombs, or other material that is
                                    malicious or technologically harmful.
                                </li>
                                <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts
                                    of the Service, the server on which the Service is stored, or any server, computer, or
                                    database connected to the Service;
                                </li>
                                <li>Attack the Service via a denial-of-service attack or a distributed denial-of-service
                                    attack; and</li>
                                <li>Otherwise attempt to interfere with the proper working of the Service.</li>
                            </ul>


                            <h3>User Contributions</h3>
                            
                            <p>The Service may contain message boards, chat rooms, personal web pages or profiles,
                                forums, bulletin boards, product listings, review pages, and other interactive features
                                (collectively, “Interactive Services”) that allow users to post, submit, publish,
                                display, or transmit to other users or other persons (hereinafter, “Post”) content or
                                materials (collectively, “User Contributions”) on or through the Service.</p>
                            
                            <p>All User Contributions must comply with the Content Standards set out in these Terms of
                                Use.</p>
                            
                            <p>Any User Contribution You post to the site will be considered non-confidential and
                                non-proprietary. By providing any User Contribution on the Service, You grant Us and Our
                                affiliates and service providers, and each of their and Our respective licensees,
                                successors, and assigns the right to use, reproduce, modify, perform, display,
                                distribute and otherwise disclose to third parties any such material for any
                                purpose.</p>
                            
                            <p>You represent and warrant that:</p>


                            <ul>
                                <li>You own or control all rights in and to the User Contributions and have the right to
                                    grant the license granted above to Us and Our affiliates and service providers, and
                                    each of their and Our respective licensees, successors, and assigns; and
                                </li>
                                <li>All of Your User Contributions do and will comply with these Terms of Use.</li>
                            </ul>

                            <p>You understand and acknowledge that You are responsible for any User Contributions You
                                submit or contribute, and You, but not the Company, have full responsibility for such
                                content, including its legality, reliability, accuracy, and appropriateness.</p>
                            
                            <p>We are not responsible, or liable to any third-party, for the content or accuracy of any
                                User Contributions posted by You or any other user of the Service.</p>


                            <h3>Monitoring and Enforcement; Termination</h3>


                            <p>We have the right to:</p>


                            <ul>
                                <li>Remove or refuse to post any User Contributions for any or no reason in Our sole
                                    discretion;
                                </li>

                                <li>Take any action with respect to any User Contribution that We deem necessary or
                                    appropriate in Our sole discretion, including, without limitation, if We believe
                                    that
                                    such User Contribution violates the Terms of Use, including the Content Standards,
                                    infringes any intellectual property right or other right of any person or entity,
                                    threatens the personal safety of users of the Service or the public, or could create
                                    liability for the Company;
                                </li>

                                <li>Disclose Your identity or other information about You to any third-party who claims
                                    that
                                    material posted by You violates their rights, including their intellectual property
                                    rights or their right to privacy;
                                </li>

                                <li>Take appropriate legal action, including, referral to law enforcement, for any
                                    illegal or
                                    unauthorized use of the Service; and
                                </li>

                                <li>Terminate or suspend Your access to all or part of the Service for any or no reason,
                                    including, without limitation, any violation of these Terms of Use.
                                </li>
                            </ul>

                            <p>Without limiting the foregoing, We have the right to fully cooperate with any law
                                enforcement authorities or court order requesting or directing Us to disclose the
                                identity or other information of anyone posting any materials on or through the Service.
                                YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE
                                PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY/ANY OF THE
                                FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN
                                AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT
                                AUTHORITIES.</p>


                            <p>We cannot review all material before it is posted on the Service and cannot ensure prompt
                                removal of objectionable material after it has been posted. Accordingly, We assume no
                                liability for any action or inaction regarding transmissions, communications, or content
                                provided by any user or third-party. We have no liability or responsibility to anyone
                                for performance or nonperformance of the activities described in this section.</p>


                            <h3>Content Standards</h3>


                            <p>These content standards apply to any and all User Contributions and use of Interactive
                                Services. User Contributions must in their entirety comply with all applicable federal,
                                state, local and international laws and regulations. Without limiting the foregoing,
                                User Contributions must not:</p>


                            <ul>
                                <li>Contain any material which is defamatory, obscene, indecent, abusive, offensive,
                                    harassing, violent, hateful, inflammatory, or otherwise objectionable;
                                </li>

                                <li>Promote sexually explicit or pornographic material, violence, or discrimination
                                    based on
                                    race, sex, religion, nationality, disability, sexual orientation, or age;
                                </li>

                                <li>Infringe any patent, trademark, trade secret, copyright, or other intellectual
                                    property
                                    or other rights of any other person;
                                </li>

                                <li>Violate the legal rights (including the rights of publicity and privacy) of others
                                    or
                                    contain any material that could give rise to any civil or criminal liability under
                                    applicable laws or regulations or that otherwise may be in conflict with these Terms
                                    of
                                    Use and Our Privacy Policy
                                    <a href="https://actiondriveneducation.com/wp-content/uploads/2020/03/Action-Driven-Education-LLC-Privacy-Policy.docx" target="_blank">https://actiondriveneducation.com/wp-content/uploads/2020/03/Action-Driven-Education-LLC-Privacy-Policy.docx</a>
                                </li>

                                <li>Be likely to deceive any person;</li>

                                <li>Promote any illegal activity, or advocate, promote, or assist any unlawful act;</li>

                                <li>Cause annoyance, inconvenience or needless anxiety to, or be likely to upset,
                                    embarrass, alarm, or annoy any other person;
                                </li>

                                <li>Impersonate any person or misrepresent Your identity or affiliation with any person
                                    or organization;
                                </li>

                                <li>Involve commercial activities or sales, such as contests, sweepstakes and other
                                    sales promotions, barter, or advertising; and
                                </li>

                                <li>Give the impression that they emanate from or are endorsed by Us or any other person
                                    or entity if this is not the case.
                                </li>
                            </ul>

                            <h3>Copyright Infringement</h3>
                            
                            <p>If You believe that any User Contributions violate Your copyright, please contact Us at
                                admin@actiondriveneducation.com for instructions on sending Us a notice of copyright
                                infringement. It is the policy of the Company to terminate the user accounts of repeat
                                infringers.</p>


                            <h3>Reliance on Information Posted</h3>
                            
                            <p>The information presented on or through the Service is made available solely for general
                                information purposes. We do not warrant the accuracy, completeness, or usefulness of
                                this information. Any reliance You place on such information is strictly at Your own
                                risk. We disclaim all liability and responsibility arising from any reliance placed on
                                such materials by You or any other visitor to the Service, or by anyone who may be
                                informed of any of its contents.</p>
                            
                            <p>The Service may from time to time include content provided by third parties, including
                                materials provided by other users, bloggers and third-party licensors, syndicators,
                                aggregators, and/or reporting services. All statements and/or opinions expressed in
                                these materials, and all articles and responses to questions and other content, other
                                than the content provided by the Company, are solely the opinions and the responsibility
                                of the person or entity providing those materials. These materials do not necessarily
                                reflect the opinion of the Company. We are not responsible or liable to You or any
                                third-party, for the content or accuracy of any materials provided by any
                                third-parties.</p>


                            <h3>Changes to the Service</h3>
                            
                            <p>We may update the content on the Service from time to time, but its content is not
                                necessarily complete or up-to-date. Any of the material on the Service may be out of
                                date at any given time, and We are under no obligation to update such material.</p>
                            
                            <h3>Information About You and Your Visits to the Service</h3>
                            
                            <p>All information We collect on the Service is subject to Our Privacy Policy
                                <a href="https://actiondriveneducation.com/wp-content/uploads/2020/03/Action-Driven-Education-LLC-Privacy-Policy.docx" target="_blank">https://actiondriveneducation.com/wp-content/uploads/2020/03/Action-Driven-Education-LLC-Privacy-Policy.docx</a>
                                . By using the Service, You consent to all actions taken by Us with respect to Your
                                information, so long as such actions remain in compliance with the Privacy Policy.</p>


                            <h3>Linking to the Service and Social Media Features</h3>
                            
                            <p>You may link to Our homepage, provided You do so in a way that is fair and legal and does
                                not damage Our reputation or take advantage of it, but You must not establish a link in
                                such a way as to suggest any form of association, approval, or endorsement on Our
                                part.</p>
                            
                            <p>The Service may provide certain social media features that enable You to:</p>


                            <ul>
                                <li>Link from Your own or certain third-party websites to certain content on the
                                    Service;
                                </li>
                                <li>Send e-mails or other communications with certain content, or links to certain
                                    content,on the Service; and
                                </li>
                                <li>Cause limited portions of content on the Service to be displayed or appear to be
                                    displayed on Your own or certain third-party websites.
                                </li>
                            </ul>

                            <p>You may use these features solely as they are provided by Us and solely with respect to
                                the content they are displayed with and otherwise in accordance with any additional
                                terms and conditions We provide with respect to such features. Subject to the foregoing,
                                You must not:</p>


                            <ul>
                                <li>Establish a link from any website that is not owned by You;</li>
                                <li>Cause the Service or portions of it to be displayed, or appear to be displayed, by
                                    actions such as framing, deep linking or in-line linking, on any other site;
                                </li>
                                <li>Link to any part of the Service other than the homepage; and</li>
                                <li>Otherwise take any action with respect to the materials on the Service that is
                                    inconsistent with any other provision of these Terms of Use.
                                </li>
                            </ul>

                            <p>The Service from which You are linking, or on which You make certain content
                                accessible, must comply in all respects with the Content Standards set out in these
                                Terms of Use.
                            </p>

                            <p>You agree to cooperate with Us in causing any unauthorized framing or linking immediately
                                to cease. We reserve the right to withdraw linking permission without notice.</p>

                            <p>We may disable all or any social media features and any links, at any time, without
                                notice, in Our discretion.</p>


                            <h3>Links from the Service</h3>


                            <p>If the Service contains links to other sites and resources provided by third parties,
                                these links are provided for Your convenience only. This includes links contained in
                                advertisements, including banner advertisements and sponsored links. We have no control
                                over the contents of those sites or resources, We and accept no responsibility for them
                                or for any loss or damage that may arise from Your use of them. If You decide to access
                                any of the third-party websites linked to the Service, You do so entirely at Your own
                                risk and subject to the terms and conditions of use for such websites.</p>


                            <h3>Geographic Restrictions</h3>

                            <p>The owner of the Service is based in the Commonwealth of Pennsylvania in the United
                                States. We provide the Service for use only by persons located in the United States. We
                                make no claims that the Service or any of its content is accessible or appropriate
                                outside of the United States. Access to the Service may not be legal by certain persons
                                or in certain countries. If You access the Service from outside the United States, You
                                do so on Your own initiative and are responsible for compliance with local laws.</p>


                            <h3>Disclaimer of Warranties</h3>

                            <p>You understand that We cannot and do not guarantee or warrant that files available for
                                downloading from the internet or the Service will be free of viruses or other
                                destructive code. You are responsible for implementing sufficient procedures and
                                checkpoints to satisfy Your particular requirements for anti-virus protection and
                                accuracy of data input and output and for maintaining a means external to Our site for
                                any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED
                                BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL
                                MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER
                                PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED
                                THROUGH THE SERVICE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
                                WEBSITE LINKED TO IT.</p>

                            <p>YOUR USE OF THE SERVICE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                                SERVICE IS AT YOUR OWN RISK. THE SERVICE, ITS CONTENT, AND ANY SERVICES OR ITEMS
                                OBTAINED THROUGH THE SERVICE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT
                                ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY
                                PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO
                                THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
                                SERVICE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH
                                THE COMPANY REPRESENTS OR WARRANTS THAT THE SERVICE, ITS CONTENT, OR ANY SERVICES OR
                                ITEMS OBTAINED THROUGH THE SERVICE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
                                UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
                                AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICE OR ANY
                                SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL OTHERWISE MEET YOUR NEEDS OR
                                EXPECTATIONS.</p>

                            <p>THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                                STATUTORY OR OTHERWISE, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
                                AND FITNESS FOR PARTICULAR PURPOSE.</p>

                            <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER
                                APPLICABLE LAW.</p>


                            <h3>Limitation on Liability</h3>

                            <p>IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS,
                                EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
                                LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE
                                SERVICE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE SERVICE OR SUCH OTHER WEBSITES OR
                                ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE OR SUCH OTHER WEBSITES, INCLUDING ANY
                                DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING
                                PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
                                PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                                DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR
                                OTHERWISE, EVEN IF FORESEEABLE.</p>

                            <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER
                                APPLICABLE LAW.</p>


                            <h3>Indemnification</h3>

                            <p>You will defend, indemnify and hold harmless the Company, its affiliates, licensors, and
                                service providers, and its and their respective officers, directors, employees,
                                contractors, agents, licensors, suppliers, successors, and assigns from and against any
                                claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees
                                (including reasonable attorneys’ fees) arising out of or relating to Your violation of
                                these Terms of Use or Your use of the Service, including Your User Contributions, any
                                use of the Service’s content, services, and products other than as expressly authorized
                                in these Terms of Use or Your use of any information obtained from the Service.</p>

                            <h3>Governing Law and Jurisdiction</h3>

                            <p>All matters relating to the Service and these Terms of Use and any dispute or claim
                                arising therefrom or related thereto (in each case, including non-contractual disputes
                                or claims), shall be governed by and construed in accordance with the internal laws of
                                the Commonwealth of Pennsylvania without giving effect to any choice or conflict of law
                                provision or rule (whether of the Commonwealth of Pennsylvania or any other
                                jurisdiction).</p>

                            <p>Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or
                                the Service shall be instituted exclusively in the federal courts of the United States
                                or the courts of the Commonwealth of Pennsylvania, in each case located in the Federal
                                Court for the Middle District of Pennsylvania. You waive any and all objections to the
                                exercise of jurisdiction over You by such courts and to venue in such courts.</p>


                            <h3>Arbitration</h3>

                            <p>At Company’s sole discretion, it may require You to submit any disputes arising from the
                                use of these Terms of Use or the Service, including disputes arising from or concerning
                                their interpretation, violation, invalidity, non-performance, or termination, to final
                                and binding arbitration under the Rules of Arbitration of the American Arbitration
                                Association, applying Pennsylvania law.</p>


                            <h3>Limitation on Time to File Claims</h3>

                            <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF
                                USE OR THE SERVICE MUST BE COMMENCED WITHIN ONE YEAR AFTER THE CAUSE OF ACTION OCCURS,
                                OR SUCH CAUSE OF ACTION OR CLAIM SHALL BE PERMANENTLY BARRED.</p>


                            <h3>Waiver and Severability</h3>

                            <p>No waiver of by the Company of any term or condition set forth in these Terms of Use
                                shall be deemed a further or continuing waiver of such term or condition or a waiver of
                                any other term or condition, and any failure of the Company to assert a right or
                                provision under these Terms of Use shall not constitute a waiver of such right or
                                provision.</p>

                            <p>If any provision of these Terms of Use is held by a court or other tribunal of competent
                                jurisdiction to be invalid, illegal or unenforceable for any reason, such provision
                                shall be eliminated or limited to the minimum extent such that the remaining provisions
                                of the Terms of Use will continue in full force and effect.</p>


                            <h3>Entire Agreement</h3>

                            <p>The Terms of Use and Privacy Policy constitute the sole and entire agreement between You
                                and Action Driven Education LLC with respect to the Service and supersede all prior and
                                contemporaneous understandings, agreements, representations and warranties, both written
                                and oral, with respect to the Service.</p>


                            <h3>Your Comments and Concerns</h3>

                            <p>The Service is operated by Action Driven Education, LLC, 164 Waterloo Drive, Meyersdale,
                                PA 15552.</p>

                            <p>All other feedback, comments, requests for technical support and other communications
                                relating to the Service should be directed to <a href="mailto:admin@actiondriveneducation.com">admin@actiondriveneducation.com</a>.</p>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    );
};


export default Terms;
