import { CLEAR_MODAL, CLOSE_MODAL, GET_TRAIT_DESCRIPTION, OPEN_MODAL } from '../types';

const initialState: any = {
    open: false,
    accomodId: null,
    modalSize: '',
    trait: { Name: '', Description: '' },
    accomod: { Key: '' }
};

export default function (state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {
        case OPEN_MODAL:
            return {
                ...state,
                open: true,
                accomodId: payload.accomodId,
                modalSize: payload.modalSize,
                trait: { Name: '', Description: '' },
                accomod: payload.fullData,
            }

        case CLOSE_MODAL:
            return {
                ...state,
                open: false,
                trait: { Name: '', Description: '' },
                accomod: { Key: '' }
                // accomodId: null,
                // modalSize: ''
            }

        case GET_TRAIT_DESCRIPTION:
            return {
                ...state,
                trait: payload
            }

        case CLEAR_MODAL:
            return {
                open: false,
                accomodId: null,
                modalSize: '',
                trait: { Name: '', Description: '' },
                accomod: { Key: '' }
            }

        default:
            return state;
    }
}
