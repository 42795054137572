//import uuid from 'uuid';
import { SET_ALERT, REMOVE_ALERT } from '../types';

export const setAlert = (key, title, errors, alertType) => (dispatch) => {
    //const key = uuid.v4();
    dispatch({
        type: SET_ALERT,
        payload: { title, errors, alertType, key }
    });
};

export const removeAlert = (key) => (dispatch) => {
    dispatch({
        type: REMOVE_ALERT,
        payload: key
    });
};