import {ADD_NEED, ADD_STRENGTH, GET_ACCOMODS, REMOVE_NEED, REMOVE_STRENGTH, SET_NEED_PRIORITY} from '../types';
import axios from "axios";
import {apiURL} from "../../services/helpers";

export const addStrength = ( accomod ) => (dispatch) => {
  dispatch({
    type: ADD_STRENGTH,
    payload: accomod
  });
}

export const addNeed = ( accomod ) => (dispatch) => {
  dispatch({
    type: ADD_NEED,
    payload: accomod
  });
}

export const removeStrength = ( accomod ) => (dispatch) => {
  dispatch({
    type: REMOVE_STRENGTH,
    payload: accomod
  });
}

export const removeNeed = ( accomod ) => (dispatch) => {
  dispatch({
    type: REMOVE_NEED,
    payload: accomod
  });
}

export const setNeedPriorities = ( needs ) => (dispatch) => {
  dispatch({
    type: SET_NEED_PRIORITY,
    payload: needs
  });
}

export const getMatchingAccomods = ( selectedNeeds, selectedStrengths ) => async dispatch => {

  const needsArray = [];
  const strengthsArray = [];

  selectedNeeds.forEach((n) => {
    needsArray.push(n.Name);
  });
  selectedStrengths.forEach((s) => {
    strengthsArray.push(s.Name);
  });

  try {
    const needs = "'Payload.NeedsPriorities': { $elemMatch: { 'Need.Name': { $in: ['"+needsArray.join("','")+"'] }}}";
    //const strengths = "'Payload.StrengthPriorities': { $elemMatch: { 'Strength.Name': { $in: ['"+strengthsArray.join("','")+"'] }}}";
    const q = "{" + needs + "}";

    const res = await axios.get(apiURL()+'/Accomods?action=get&i=0&size=250&q='+encodeURI(q));

    /**********************************************/
    let accoClone = [];

    accoClone = [...res.data.r];

    /// CALCULATE SCORE FOR EACH ACCOMOD
    accoClone.map( (doc, i) => {
      let score = 0;
      if (doc.NeedsPriorities) {
        doc.NeedsPriorities.forEach( (accoNeeds) => {
          selectedNeeds.forEach( (userNeeds) => {
            if ( accoNeeds.Need.Name === userNeeds.Name ) {
              score += userNeeds.priority * accoNeeds.Priority;
            }
          });
        });
      }
      if (doc.StrengthPriorities) {
        doc.StrengthPriorities.forEach( (accoStrengths) => {
          selectedStrengths.forEach( (userStrengths) => {
            if (accoStrengths.Strength.Name === userStrengths.Name ) {
              score += accoStrengths.Priority;
            }
          });
        });
      }
      //console.log('scoring '+i+': '+score);
      doc.score = score;
      return doc;
    });

    /// SORT THE ACCOMODS
    accoClone.sort((a, b) => (a.score < b.score) ? 1 : -1);

    /**********************************************/

    dispatch({
      type: GET_ACCOMODS,
      payload: accoClone
    });

  } catch(err) {
    console.log('Error getting matching accomods', err);
  }

}
