import React from 'react';
import { connect } from 'react-redux';
import AlertComponent from '../../shared/components/Alert';
import { v4 as guid } from 'uuid';

const Alert = (props: any) => {
    const { alert, alertKey } = props;

    let thisAlert = [];
    if (alert && alert.length > 0) {
        thisAlert = alert.filter((al: any) => {
            return al.key === alertKey
        });
        if (thisAlert.length) thisAlert = thisAlert[ 0 ];
    }

    return (
        thisAlert && (thisAlert.title || thisAlert.errors && thisAlert.errors.length) ?
            <AlertComponent color={thisAlert.alertType} icon={true} className="alert--bordered" alertKey={thisAlert.key}>
                {thisAlert.title.length > 0 && <strong>{thisAlert.title}</strong>}
                {thisAlert.errors.map((err: any) => {
                    let theError = '';
                    if (err && err.message && typeof err.message === 'string') {
                        theError = err.message;
                    } else if (typeof err === 'string') {
                        theError = err;
                    } else {
                        theError = 'Unknown error';
                    }
                    return (<div key={guid()}>{ theError }</div>)
                })}
            </AlertComponent>
            : <></>
    )
};

const mapStateToProps = (state: any, ownProps?: any) => ({
    alert: state.alert,
    alertKey: ownProps.alertKey,
});

export default connect(mapStateToProps)(Alert);

