import React from 'react';
import { Col, Container, Row } from "reactstrap";
import PageTitle from '../../components/PageTitle';
import Topbar from '../Layout/topbar/Topbar';

const Privacy = (props: any) => {

    return (
        <>
            <Topbar />

            <div className={'acco-terms'}>

                <PageTitle title='Privacy Policy'/>
                <Container>
                    <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                            <p><b>Last Modified: March, 2020</b></p>

                            <h3>Introduction</h3>
                            <p>Action Driven Education, LLC (the “<b>Company</b>,” “<b>We</b>,” “<b>Us</b>,” or “<b>Our</b>”) 
                                respects Your privacy and is committed to protecting it through Our compliance with this Privacy Policy (the “<b>Policy</b>”).</p>
                            <p>This Policy describes the types of information We may collect from You or that You may
                                provide when visit the website www.actiondriveneducation.com (the “<b>Website</b>”) and Our
                                practices for collecting, using maintaining, protecting, and disclosing that
                                information.</p>

                            <p>This Policy applies to information We collect:</p>
                            <ul>
                                <li>On this Website;</li>
                                <li>In email, text, and other electronic messages between You and this Website; and</li>
                                <li>When You interact with our advertising and application on third-party websites and
                                    services, if those applications or advertising include links to this Policy.</li>
                            </ul>


                            <p>It does not apply to information collected by:</p>
                            <ul>
                                <li>Us offline or through any other means, including on any other website operated by
                                    the Company or any third-party (including Our affiliates and subsidiaries, as
                                    applicable); or
                                </li>
                                <li>Any third-party (including Our affiliates and subsidiaries), including through any
                                    application or content (including advertising) that may link to or be accessible
                                    from
                                    (or on) the Website.
                                </li>
                            </ul>

                            <p>Our other websites, if applicable, and these third-parties may have their own privacy
                                policies, which We encourage You to read before providing information on or through
                                them.</p>
                            <p>Please read this Policy carefully to understand Our policies and practices regarding Your
                                information and how We will treat it. If You do not agree with Our policies and
                                practices, You have the option is to not utilize the Website. By accessing or using the
                                Website, You agree to the terms of this Policy. This Policy may change from time to time
                                (see Changes to Our Privacy Policy). Your continued use of the Website after We make
                                changes is deemed to be acceptance of those changes, so please check the Policy
                                periodically for updates.</p>

                            <h3>Children Under the Age of 18</h3>
                            <p>Our Website is not intended for children under 18 years of age. No one under age 18 may
                                provide any information to the Website. We do not knowingly collect personal information
                                from children under 18. If You are under 18, do not use or provide any information on
                                the Website or on or through any of its features/register on the Website, make any
                                purchases through the Website, use any of the interactive or public comment features of
                                the Website, or provide any information about Yourself to Us, including Your name,
                                address, telephone number, email address, or any screen name or user name You may use.
                                If We learn We have collected or received personal information from a child 18, We will
                                delete that information. If You believe We might have any information from or about a
                                child under 18, please contact Us at <a href="mailto:admin@actiondriveneducation.com">admin@actiondriveneducation.com</a>.</p>



                            <h3>Information We Collect About You and How We Collect It</h3>

                            <p>We collect several types of information from and about users of the Website, including
                                information:</p>
                            <ul>
                                <li>By which You may be personally identified, such as name, postal address, telephone,
                                    or e-mail address (“<b>Personal Information</b>”);
                                </li>
                                <li>That is about You but individually does not identify You, such as demographic
                                    information; and
                                </li>
                                <li>About Your internet connection, the equipment You use to access Our Website, and
                                    usage details.
                                </li>
                            </ul>

                            <p>We collect this information:</p>
                            <ul>
                                <li>Directly from You when You provide it to Us;</li>
                                <li>Automatically as You navigate through the Website. Information collected
                                    automatically may include usage details, IP addresses, and information collected
                                    through cookies, web beacons, and other tracking technologies.
                                </li>
                                <li>When applicable, from third-parties.</li>
                            </ul>

                            <h3>Information You Provide to Us.</h3>

                            <p>The information We collect on or through the Website may include:</p>


                            <ul>
                                <li>Information that You provide by filling in forms on the Website, including
                                    information provided at the time of registering to use the Website, posting material, or
                                    requesting further services. We may also ask You for information when You report a problem with
                                    the Website;
                                </li>
                                <li>Records and copies of Your correspondence (including email addresses), if You
                                    contact Us;
                                </li>
                                <li>Your responses to surveys that We might ask You to complete for research purposes;
                                </li>
                                <li>Details of transactions You carry out through the Website and fulfillment of Your
                                    orders. You may be required to provide financial information before placing an order through
                                    the Website; and
                                </li>
                                <li>Your search queries on the Website.</li>
                            </ul>

                            <p>You may also provide information to be published or displayed on public areas of the
                                Website or transmitted to other users of the Website or third-parties (collectively,
                                “<b>User Contributions</b>”). Your User Contributions are posted on and transmitted to others
                                at Your own risk. Please be aware that no security measures are perfect or impenetrable.
                                Additionally, We cannot control the actions of other users of the Website with whom You
                                may choose to share Your User Contributions. Therefore, We cannot and do not guarantee
                                that Your User Contributions will not be viewed by unauthorized persons.</p>



                            <h3>Information We Collect Through Automatic Data Collection Technologies.</h3>

                            <p>As You navigate through and interact with the Website, We may use automatic data
                                collection technologies to collect certain information about Your equipment, browsing
                                actions, and patterns, including:</p>

                            <ul>
                                <li>Usage Details. Details of Your visits to the Website, including traffic data,
                                    location data, logs, and other communication data and the resources that You access and use
                                    on the Website; and
                                </li>
                                <li>Device Information. Information about Your computer, internet connection, and mobile
                                    device, including Your IP address, operating system, phone model, mobile network,
                                    device’s telephone number, and browser type.
                                </li>
                            </ul>

                            <p>We may also use these technologies to collect information about Your online activities
                                over time and across third-party websites or other online services (behavioral
                                tracking). Please contact us admin@actiondriveneducation.com if You do not want us to
                                collect this information and for information on how You can opt out of behavioral
                                tracking on or through this Website and how we respond to browser signals and other
                                mechanisms that enable consumers to exercise choice about behavioral tracking.</p>


                            <h3>Information Collection and Tracking Technologies</h3>

                            <p>The information We collect automatically is statistical data and does not include
                                personal information, but We may maintain it or associate it with personal information
                                We collect in other ways or receive from third-parties. It helps Us to improve the
                                Website and to deliver better and more personalized services, including by enabling Us
                                to:</p>


                            <ul>
                                <li>Estimate Our audience size and usage patterns;</li>
                                <li>Store information about Your preferences and/or favorite listings on the Website,
                                    allowing Us to customize the Website according to Your individual interests;
                                </li>
                                <li>Speed up Your searches; and</li>
                                <li>Recognize You when You return to the Website.</li>
                            </ul>


                            <p>The technologies We use for this automatic data collection may include:</p>


                            <ul>
                            <li><b>Cookies (or browser cookies).</b> A cookie is a small file placed on the hard drive of Your
                                computer. You may refuse to accept browser cookies by activating the appropriate setting
                                on Your browser. However, if You select this setting, You may be unable to access
                                certain parts of the Website. Unless You have adjusted Your browser setting so that it
                                will refuse cookies, Our system will issue cookies when You direct Your browser to the
                                Website.</li>
                            <li><b>Flash Cookies.</b> Certain features of the Website may use locally stored objects (or Flash
                                cookies) to collect and store information about Your preferences and navigation to,
                                from, and on the Website. Flash cookies are not managed by the same browser settings as
                                are used for browser cookies. For information about managing Your privacy and security
                                settings for Flash cookies, contact us at admin@actiondriveneducation.com.</li>
                            <li><b>Web Beacons.</b> Pages on the Website and Our e-mails may contain small electronic files
                                known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs)
                                that permit the Company to count users who have visited those pages, opened an email, or
                                for other related website statistics such as recording the popularity of certain website
                                content and verifying system and server integrity.</li>
                            </ul>

                            <p>We do not collect personal information automatically, but We may tie this information to
                                personal information about You that We collect from other sources or that You provide to
                                Us.</p>


                            <h3>Third-Party Information Collection</h3>

                            <p>When You use the Website or its content, certain third-parties may use automatic
                                information collection technologies to collect information about You or Your device.
                                These third-parties may include:</p>

                            <ul>
                                <li>Advertisers, ad networks, and ad servers; and</li>
                                <li>Your service provider.</li>
                            </ul>

                            <p>Some content or applications, including advertisements, on the Website are served by
                                third-parties, including advertisers, ad networks, and servers, content providers, and
                                application providers. These third-parties may use cookies (alone or in conjunction with
                                web beacons or other tracking technologies) to collect information about You when You
                                use this Website. The information they collect may be associated with Your personal
                                information or they may collect information, including personal information, about Your
                                online activities over time and across different websites, apps, and other online
                                services websites. They may use this information to provide You with interest-based
                                (behavioral) advertising or other targeted content.</p>

                            <p>We do not control these third-parties' tracking technologies or how they may be used. If
                                You have any questions about an advertisement or other targeted content, You should
                                contact the responsible provider directly. For information about how You can opt out of
                                receiving targeted advertising from many providers, see “Choices About How We Use and
                                Disclose Your Information.”</p>


                            <h3>How We Use Your Information</h3>

                            <p>We use information that We collect about You or that You provide to Us, including any
                                personal information, to:</p>


                            <ul>
                                <li>Present our Website and its contents to You;</li>
                                <li>Provide You with information, products, or services that You request from Us;</li>
                                <li>Fulfill any other purpose for which You provide it;</li>
                                <li>Provide You with notices about Your account and subscription, respond to comments,
                                    questions, and provide customer service;
                                </li>
                                <li>To carry out Our obligations and enforce Our rights arising from any contracts
                                    entered into between You and Us, including for billing and collection;
                                </li>
                                <li>Notify You about changes to the Website or any products or services We offer or
                                    provide though it;
                                </li>
                                <li>Allow You to participate in interactive features on the Website;</li>
                                <li>Communicate about promotions, upcoming events, and other news about products and
                                    services offered by Us and Our selected partners;
                                </li>
                                <li>In any other way We may describe when You provide the information; and</li>
                                <li>For any other purpose with Your consent.</li>
                            </ul>


                            <p>The usage information We collect helps us to improve Our Website and to deliver a better
                                and more personalized experience by enabling Us to:</p>


                            <ul>
                                <li>Estimate Our audience size and usage patterns;</li>
                                <li>Store information about Your preferences, allowing us to customize Our Website
                                    according to Your individual interests;
                                </li>
                                <li>Speed up Your searches; and</li>
                                <li>Recognize when You use the Website.</li>
                            </ul>


                            <p>We may also use Your information to contact You about Our own and third-parties' goods
                                and services that may be of interest to You.</p>

                            <p>We may use the information we collect to display advertisements to Our advertisers'
                                target audiences. Even though We do not disclose Your personal information for these
                                purposes without Your consent, if You click on or otherwise interact with an
                                advertisement, the advertiser may assume that You meet its target criteria.</p>

                            
                            <h3>Disclosure of Your Information</h3>
                            
                            <p>We may disclose aggregated information about Our users, and information that does not
                                identify any individual, without restriction.</p>
                            
                            <p>We may disclose personal information that We collect or You provide as described in this
                                Policy:</p>


                            <ul>
                                <li>To Our subsidiaries and affiliates;</li>
                                <li>To contractors, service providers, and other third-parties We use to support Our
                                    business and who are bound by contractual obligations to keep personal information
                                    confidential and use it only for the purposes for which We disclose it to them;
                                </li>
                                <li>To a buyer or other successor in the event of a merger, divestiture, restructuring,
                                    reorganization, dissolution, or other sale or transfer of some or all of the
                                    Company’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar
                                    proceeding, in which personal information held by the Company about the Website’s
                                    users is among the assets transferred;
                                </li>
                                <li>To third-parties to market their products or services to You. We contractually
                                    require these third parties to keep personal information confidential and use it only for
                                    the purposes for which we disclose it to them;
                                </li>
                                <li>To fulfill the purpose for which You provide it;</li>
                                <li>For any other purpose disclosed by Us when You provide the information; and</li>
                                <li>With Your consent.</li>
                            </ul>

                            <p>We may also disclose Your personal information:</p>


                            <ul>
                                <li>To comply with any court order, law, or legal process, including to respond to any
                                    government or regulatory request;
                                </li>

                                <li>To enforce or apply Our Terms of Use and other agreements, including for billing and
                                    collection purposes; and
                                </li>

                                <li>If We believe disclosure is necessary or appropriate to protect the rights,
                                    property, or
                                    safety of the Company, Our customers, or others, including exchanging information
                                    with
                                    other companies and organizations for the purposes of fraud protection and credit
                                    risk
                                    reduction.
                                </li>
                            </ul>



                            <h3>Choices About How We Use and Disclose Your Information</h3>

                            <p>We strive to provide You with choices regarding the personal information You provide to
                                Us. We have created mechanisms to provide You with the following control over Your
                                information:</p>


                            <ul>
                                <li><b>Tracking Technologies and Advertising.</b> You can set Your browser to refuse all or
                                    some
                                    browser cookies, or to alert You when cookies are being sent. To learn how You can
                                    manage Your Flash cookie settings, visit the Flash player settings page on Adobe’s
                                    website. If You disable or refuse cookies, please note that some parts of this site
                                    may
                                    then be inaccessible or not function properly.
                                </li>

                                <li><b>Promotional Offers from the Company.</b> If You do not wish to have Your email
                                    address/contact information used by the Company to promote Our own or third-parties’
                                    products or service may send us an email asking to be omitted from disclosures of
                                    this
                                    nature. If We have sent You a promotional email, You may send Us a return email
                                    asking
                                    to be omitted from future email distributions.
                                </li>

                                <li><b>Targeted Advertising by the Company.</b> If You do not want Us to use information that
                                    We
                                    collect or that You provide to Us to deliver advertisements according to Our
                                    advertisers' target-audience preferences, You can opt-out by emailing
                                    <a href="mailto:admin@actiondriveneducation.com">admin@actiondriveneducation.com</a>.
                                </li>

                                <li><b>Disclosure of Your Information for Third-Party Advertising and Marketing.</b> If You do
                                    not
                                    want Us to share Your personal information with unaffiliated or non-agent
                                    third-parties
                                    for advertising and marketing purposes, You can opt-out by emailing
                                    <a href="mailto:admin@actiondriveneducation.com">admin@actiondriveneducation.com</a>.
                                </li>
                            </ul>


                            <p>We do not control third-parties’ collection or use of Your information to serve
                                interest-based advertising. However, these third-parties may provide You with ways to
                                choose not to have Your information collected or used in this way. You can opt out of
                                receiving targeted ads from members of the Network Advertising Initiative (“<b>NAI</b>”) on the
                                NAI’s website.</p>

                            <h3>Accessing and Correcting Your Information</h3>

                            <p>You can review and change Your personal information by sending Us an email at
                                admin@actiondriveneducation.com to request access to, correct, or delete any personal
                                information that You have provided to Us. We may not accommodate a request to change
                                information if We believe the change would violate any law or legal requirement or cause
                                the information to be incorrect.</p>
                            
                            <p>If You delete Your User Contributions from the Website, copies of Your User Contributions
                                may remain viewable in cached and archived pages or might have been copied or stored by
                                other Website users. Proper access and use of information provided on the Website,
                                including User Contributions, is governed by Our Terms of Use.</p>



                            <h3>Data Security</h3>

                            <p>We have implemented measures designed to secure Your personal information from accidental
                                loss and from unauthorized access, use, alteration, and disclosure. The safety and
                                security of Your information also depends on You. Where we have given You (or where You
                                have chosen) a password for access to certain parts of our Website, You are responsible
                                for keeping this password confidential. We ask You not to share Your password with
                                anyone.</p>
                            
                            <p>Unfortunately, the transmission of information via the internet is not completely secure.
                                Although We take reasonable actions to protect Your personal information, We cannot
                                guarantee the security of Your personal information transmitted to the Website. Any
                                transmission of personal information is at Your own risk. We are not responsible for
                                circumvention of any privacy settings or security measures contained on the Website.</p>



                            <h3>Changes to Our Privacy Policy</h3>
                            
                            <p>It is Our policy to post any changes We make to our Privacy Policy on this page with a
                                notice on the Website’s home page that the Privacy Policy has been updated. If We make
                                material changes to how We treat Our users’ personal information, We will notify You by
                                email to the email address provided or through a notice on the home page for the
                                Website. The date the Privacy Policy was last revised is identified at the top of the
                                page. You are responsible for ensuring We have an up-to-date active and deliverable
                                email address for You, and for periodically visiting Our Website and this Privacy Policy
                                to check for any changes.</p>



                            <h3>Contact Information</h3>


                            <p>To ask questions or comment about this Privacy Policy and Our privacy practices, contact
                                Us at <a href="mailto:admin@actiondriveneducation.com">admin@actiondriveneducation.com</a>.</p>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    );
};


export default Privacy;
