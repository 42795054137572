import { BROWSE_ACCOMODS_CATEGORY, BROWSE_CLEAR_ACCOMODS_CATEGORY, CLEAR_BROWSER } from '../types';

const initialState: any = {
    activeCategory: '',
    categoryData: [],
    accomods: [],
    isLoading: true
};

export default function (state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {

        case BROWSE_ACCOMODS_CATEGORY:
            return {
                ...state,
                activeCategory: payload.categoryId,
                categoryData: payload.categoryData,
                accomods: payload.accomods,
                isLoading: false
            };

        case BROWSE_CLEAR_ACCOMODS_CATEGORY:
            return {
                ...state,
                activeCategory: '',
                categoryData: [],
                accomods: [],
                isLoading: true
            };

        case CLEAR_BROWSER:
            return {
                activeCategory: '',
                categoryData: [],
                accomods: [],
                isLoading: true
            }

        default:
            return state;
    }
}
