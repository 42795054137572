import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { logout } from '../../../redux/actions/auth';
import logo from '../../../shared/img/logo.png';

import { connect } from 'react-redux';

const Topbar = (props: any) => {

    const { logout, userData, profile, auth, cart } = props;
    const [menuOpen, toggleMenu] = useState(false);

    const navigate = useNavigate();

    //const avatar = userData && userData.avatar ? userData.avatar : 'user/avatars/default';

    const fname = profile && profile.Name && profile.Name.First ? profile.Name.First : '';
    const lname = profile && profile.Name && profile.Name.Last ? profile.Name.Last : '';
    const name = fname + ' ' + lname;

    return (
        <header id="page-head">
            <div><Link to={'/'}><img src={logo} /></Link></div>
            { auth.access_token ?
                <div className="action-group">
                    <div className="button flex" onClick={() => toggleMenu(!menuOpen)}>
                        <div>{ name }</div>
                        <div><i className="fad fa-caret-down"></i></div>
                        <Collapse isOpen={menuOpen} className="topbar__menu-wrap">
                            <div className="topbar__menu">
                                <Link className="topbar__link" to={"/my-profile"}>
                                    My Profile
                                </Link>
                                <div className="topbar__menu-divider"/>
                                <a className="topbar__link" href="https://action-driven-education.thrivecart.com/updateinfo/" target="_blank">
                                    My Account
                                </a>
                                <div className="topbar__menu-divider"/>
                                <a className="topbar__link" href="https://actiondriveneducation.com/action-driven-pd-for-members/" target="_blank">
                                    Professional Development
                                </a>
                                <div className="topbar__menu-divider"/>
                                <Link className="topbar__link" onClick={logout} to={`#`}>
                                    Sign Out
                                </Link>
                            </div>
                        </Collapse>
                    </div>
                    <div className="button flex" onClick={()=>navigate('/plan')}>
                        <div className="cartbadge">{ cart && cart.items ? cart.items.length : 0 }</div>
                        <div>My Plan</div>
                    </div>
                </div>
            :
                <div></div>
            }
        </header>
    );

}


const mapStateToProps = (state: any) => ({
    cart: state.cart,
    profile: state.profile.profile,
    auth: state.auth
});

export default connect(mapStateToProps, { logout })(Topbar);
