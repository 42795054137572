import { CLEAR_PROFILE, GET_PROFILE, PROFILE_ERROR } from '../types';

const initialState = {
    profile: null,
    loading: true,
    error: {}
}

export default function ( state = initialState, action: any) {
    const { type, payload } = action;

    switch(type) {
        case GET_PROFILE:
            return {
                ...state,
                profile: payload,
                loading: false
            }
        case CLEAR_PROFILE:
            return {
                ...state,
                profile: null,
                loading: false,
            }
        case PROFILE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            }
        default:
            return state;
    }
}
