import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../redux/actions/alert';
import { registerUser } from '../../redux/actions/auth';
import { Col, Container, Row } from "reactstrap";
import Alert from '../Layout/Alert';
import { Navigate } from 'react-router';
import { useForm } from 'react-hook-form';
import PageTitle from '../../components/PageTitle';
import Topbar from '../Layout/topbar/Topbar';

const RegisterCode = (props: any) => {
    const [showPassword, toggleShowPassword] = useState(false);

    const { setAlert, registerUser, auth } = props;

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            FirstName: '',
            LastName: '',
            UserEmail: '',
            Phone: '',
            UserPassword: '',
        }
    });

    const onSubmit = (values: any) => {
        //e.preventDefault();
        registerUser({
            fname: values.FirstName,
            lname: values.LastName,
            email: values.UserEmail,
            phone: values.Phone,
            password: values.UserPassword,
            district: auth.district.name,
            districtCode: auth.district.code,
            acctType: 'district'
        });
    };


    // Redirect if already signed in
    if (auth && auth.isAuthenticated) {
        return <Navigate to="/dashboard"/>
    }

    // Redirect if no valid school code
    if ( auth && auth.district && !auth.district.code ) {
        return <Navigate to="/register"/>
    }

    return (
        <>
            <Topbar />

            <div className={'acco-register'}>

                <PageTitle title='Create District Account'
                           subtitle={'District accounts are for users whose school or school district has a pre-paid Accomods license.'}/>

                <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <h4 className="form-set-header">Account Information</h4>
                                <div className="form-set">
                                    <div className={`form-group`}>
                                        <label>First Name {errors.FirstName ? <span>First Name Is Required</span> : <></>}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("FirstName", { required: true, minLength: 1, maxLength: 64 })}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label>Last Name {errors.LastName ? <span>Last Name Is Required</span> : <></>}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("LastName", { required: true, minLength: 1, maxLength: 64 })}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label>
                                            {errors.UserEmail ? <span>Email Is Invalid</span> : <></>}
                                            Email Address
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="jdoe@example.com"
                                            className={`form-control`}
                                            {...register("UserEmail", {
                                                required: 'Required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label>Phone Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="321-555-1212"
                                            {...register("Phone", { minLength: 1, maxLength: 20 })}
                                        />
                                    </div>
                                </div>

                                <h4 className="form-set-header">Set Password</h4>
                                <div className="form-set">
                                    <div className={`form-group`}>
                                        <label>
                                            {errors.UserPassword && <span>Password Must Be At Least 8 Characters</span>}
                                            Password
                                        </label>
                                        <div className="auth-form-group">
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                className="form-control input-ico-right"
                                                {...register("UserPassword", { required: true, minLength: 8, maxLength: 64 })}
                                            />
                                            <div className="ico-right"
                                                 onClick={() => toggleShowPassword(!showPassword)}>
                                                <i className={`fas ` + (showPassword ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col md={6}>
                                <h4 className="form-set-header">District Information</h4>
                                <p><b>District Name:</b> &nbsp; { auth.district.name }</p>
                                <br/><br/>

                                <hr/>

                                <div className="text-center">
                                    <p>By clicking the CREATE ACCOUNT button, you are indicating that you agree to the
                                        Accomods <a href="/terms" target="_blank">Terms of Use</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.</p>
                                    <br/><br/>
                                    <input type="submit" className="btn btn-primary btn-pill btn-lg" value="Create Account" />

                                    <br/><Alert alertKey="regCode"/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </form>

            </div>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    auth: state.auth
});

export default connect(mapStateToProps, {setAlert, registerUser})(RegisterCode);
