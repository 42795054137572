import React from 'react';
import { Link } from 'react-router-dom';


const BuilderNav = (props: any) => {

    const { step } = props;

    return (
        <ul className='acco-builder-navlist'>
            { step > 1 ?
                <li className='on'><Link to={'/builder/strengths'}><i>1</i> Identify Strengths</Link></li>
                :
                <li className={ step===1 ? 'on' : 'off'}><div><i>1</i> Identify Strengths</div></li>
            }
            { step > 2 ?
                <li className='on'><Link to={'/builder/needs'}><i>2</i> Identify Needs</Link></li>
                :
                <li className={ step===2 ? 'on' : 'off'}><div><i>2</i> Identify Needs</div></li>
            }
            { step > 3 ?
                <li className='on'><Link to={'/builder/prioritize'}><i>3</i> Prioritize Needs</Link></li>
                :
                <li className={ step===3 ? 'on' : 'off'}><div><i>3</i> Prioritize Needs</div></li>
            }
            { step > 4 ?
                <li className='on'><Link to={'/builder/select'}><i>4</i> Select Accommodations</Link></li>
                :
                <li className={ step===4 ? 'on' : 'off'}><div><i>4</i> Select Accommodations</div></li>
            }
            { step > 5 ?
                <li className='on'><Link to={'/builder/export'}><i>5</i> Export / Share Plan</Link></li>
                :
                <li className={ step===5 ? 'on' : 'off'}><div><i>5</i> Export / Share Plan</div></li>
            }
        </ul>
    );
};

export default BuilderNav;
