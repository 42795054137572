import { combineReducers } from 'redux';
import alert from './reducers/alert';
import auth from './reducers/auth';
import profile from './reducers/profile';
import modal from './reducers/modal';
import cart from './reducers/cart';
import builder from './reducers/builder';
import browser from './reducers/browser';
import search from './reducers/search';
import data from './reducers/data'

export default combineReducers({
    alert, auth, profile, modal, cart, builder, browser, search, data
});