import axios from 'axios';
import {
  CLEAR_BROWSER,
  CLEAR_BUILDER,
  CLEAR_CART,
  CLEAR_DATA,
  CLEAR_MODAL,
  CLEAR_PROFILE,
  CLEAR_SEARCH,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_DISTRICT,
  USER_LOADED
} from '../types';

import {removeAlert, setAlert} from "./alert";
import {clearAuthToken, setAuthToken} from "../../utils/setAuthToken";
import {accoApiUrl} from '../../services/helpers';
import {getCurrentProfile} from "./profile";

// const qs = require('querystring')


// LOAD USER
export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    dispatch({type: USER_LOADED});
  }
  if (localStorage.userName) {
    dispatch(getCurrentProfile( localStorage.userName ));
  }
};


// LOOKUP SCHOOL CODE
export const schoolDistrictLookup = (districtCode) => async (dispatch) => {
  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.get(accoApiUrl() + '/school/code/' + districtCode, config);
    //console.log(res);
    if (res.data.success) {
      const data = {
        name: res.data.payload.school_name,
        code: res.data.payload.school_code,
        isActive: res.data.payload.active,
        expires: res.data.payload.expires,
      };
      dispatch({
        type: SET_DISTRICT,
        payload: data,
      });
    } else {
      dispatch(setAlert('codeError', 'Invalid Code Entered.', [], 'danger'));
    }

  } catch (err) {
    console.log('error');
  }
}


export const setStoredLocalData = () => async (dispatch) => {
  dispatch({type: USER_LOADED});
}

// Login User
export const login = ({userOrEmail, password}) => async (dispatch) => {

  try {

    dispatch({type: LOGOUT});

    const timestamp = new Date().getTime();
    const config = { headers: { 'Content-Type': 'application/json' } };
    const data = {
      "email": userOrEmail,
      "password": password
    }

    const res = await axios.post(accoApiUrl() + '/auth/token/?ts='+timestamp, data, config);

    if (res.data.success) {
      setAuthToken(res.data.access_token);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(removeAlert('signinError'));
      dispatch(getCurrentProfile( res.data.userName ));

    } else {
      console.log('login error');
      //const errors = err; //err.response.data.errors;
      dispatch(setAlert('signinError', 'There was an error signing in:', [res.data.msg], 'danger'));
      dispatch({type: LOGIN_FAIL});
    }

  } catch (err) {
    console.log(err);
    //const errors = err; //err.response.data.errors;
    console.log('err', err);
    dispatch(setAlert('signinError', 'There was an error signing in.', [err], 'danger'));
    dispatch({type: LOGIN_FAIL});
  }
};


// Register User
export const registerUser = (regData) => async (dispatch) => {
  const {fname, lname, email, password, phone, district, districtCode, acctType} = regData;

  try {
    dispatch(removeAlert('regCode'));

    const data = {
      email: email,
      fname: fname,
      lname: lname,
      school: district,
      code: districtCode,
      phone: phone,
      password: password,
      type: acctType
    };

    const res = await axios.post(accoApiUrl() + '/auth/register', data);

    if (res.data.success) {
      //await sendConfirmationEmail( regData );
      dispatch({type: REGISTER_SUCCESS, payload: res.data.payload});
      dispatch(loadUser());

    } else {
      dispatch(setAlert('regCode', 'There was a problem creating your account.', [res.data.error], 'danger'));
      dispatch({type: REGISTER_FAIL});
    }

  } catch (err) {
    //const errors = err.response.data.errors;
    dispatch(setAlert('regCode', 'An unknown error occurred while creating your account.', [], 'danger'));
    dispatch({type: REGISTER_FAIL});
  }

};


// Logout User / Clear Profile
export const logout = () => dispatch => {
  clearAuthToken();
  dispatch({type: CLEAR_DATA});
  dispatch({type: CLEAR_BROWSER});
  dispatch({type: CLEAR_BUILDER});
  dispatch({type: CLEAR_CART});
  dispatch({type: CLEAR_MODAL});
  dispatch({type: CLEAR_SEARCH});
  dispatch({type: CLEAR_PROFILE});
  dispatch({type: LOGOUT});
}


// UPDATE PASSWORD
export const updatePassword = (email, old, new1, new2) => async (dispatch) => {
  dispatch(removeAlert('pwUpdate'));
  try {
    const data = {
      "u": email,
      "p": old,
      "p1": new1,
      "p2": new2
    }
    const config = { headers: { 'Content-Type': 'application/json' } };
    const res = await axios.put(accoApiUrl() + '/auth/pwreset', data, config);

    if (res.data.success) {
      dispatch(setAlert('pwUpdate', 'Password update successful.', [], 'success'));
      return true;

    } else {
      dispatch(setAlert('pwUpdate', 'Error updating password!', [res.data.error], 'danger'));
      return false;
    }
  } catch (err) {
    console.log('password update error');
  }
}


// SEND WELCOME EMAIL
// const sendConfirmationEmail = async ( values ) => {
//     const requestDate = new Date();
//     const data = {
//         api_key: process.env.REACT_APP_SMTP2GO_API,
//         to: [values.fname + ' ' + values.lname + ' <' + values.email + '>'],
//         sender: 'Accomods <support@accomods.com>',
//         subject: 'Welcome to Accomods!',
//         text_body: 'Welcome to Accomods!',
//         html_body: '<p><b>Welcome to Accomods!</b></p>',
//
//         custom_headers: [
//             {
//                 header: 'Reply-To',
//                 value: 'Accomods <support@accomods.com>'
//             }
//         ]
//     };
//
//     try {
//         const sendEmail = await axios.post('https://api.smtp2go.com/v3/email/send', data);
//     } catch (err) {
//         console.log(err);
//     }
// }
