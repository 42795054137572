import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { mapClass } from '../../services/FormService';
import { connect } from 'react-redux';
import PageTitle from '../../components/PageTitle';
import { updatePassword } from '../../redux/actions/auth';
import { setAlert } from '../../redux/actions/alert';
import Alert from '../Layout/Alert';


const MyProfile = (props: any) => {

    const { auth, profile, updatePassword, setAlert } = props;

    const passwordData = {
        u: auth.userName,
        old: '',
        new1: '',
        new2: '',
    };

    const [showPassword, toggleShowPassword] = useState(false);
    const [showPassword1, toggleShowPassword1] = useState(false);
    const [showPassword2, toggleShowPassword2] = useState(false);
    const [emailOK, setEmailOK] = useState(-1);
    const [pwData, setPwData] = useState( passwordData );

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            FirstName: profile && profile.profile && profile.profile.Name && profile.profile.Name.First ? profile.profile.Name.First : '',
            LastName: profile && profile.profile && profile.profile.Name && profile.profile.Name.Last ? profile.profile.Name.Last : '',
            UserEmail: profile && profile.profile && profile.profile.EmailAddress ? profile.profile.EmailAddress : ''
        }
    });

    const onUpdatePw = ( e:any ) => {
        let pwTemp:any = { ...pwData };
        pwTemp[e.target.name] = e.target.value;
        setPwData(pwTemp);
    }

    const submitPasswordUpdate = async (e:any) => {
        e.preventDefault();
        const { u, old, new1, new2 } = pwData;
        const result = await updatePassword( auth.userName, old, new1, new2 );
        if (result) setPwData( passwordData );
    }

    const onSubmit = (values: any) => {
        //e.preventDefault();
        //console.log(values);
    };

    return (
        <>

            <div className={'acco-register acco-profile'}>

                <PageTitle title='My Profile'/>

                <Container className="dashboard">
                    <Row>
                        <Col md={6}>
                            <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
                                <h4 className="form-set-header">Account Information</h4>
                                <hr/>
                                <div className="form-set">
                                    <div className={`form-group`}>
                                        <label>First Name {errors.FirstName ?
                                            <span>First Name Is Required</span> : <></>}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("FirstName", { required: true, minLength: 1, maxLength: 64 })}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label>Last Name {errors.LastName ?
                                            <span>Last Name Is Required</span> : <></>}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("LastName", { required: true, minLength: 1, maxLength: 64 })}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label>
                                            {errors.UserEmail ? <span>Email Is Invalid</span> : emailOK === 2 &&
                                              <span>Email Already In Use</span>}
                                            Email Address
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="jdoe@example.com"
                                            className={`form-control ` + mapClass(emailOK, !!errors.UserEmail)}
                                            {...register("UserEmail", {
                                                required: 'Required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })}
                                            disabled={true}
                                        />
                                    </div>
                                    {/*<div className={`form-group`}>*/}
                                    {/*    <label>Phone Number</label>*/}
                                    {/*    <input*/}
                                    {/*        name="LastName"*/}
                                    {/*        type="text"*/}
                                    {/*        className="form-control"*/}
                                    {/*        placeholder="321-555-1212"*/}
                                    {/*        {...register({ required: true, minLength: 1, maxLength: 64 })}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className={`form-group`}>
                                        <br/>
                                        {/*<button className='btn btn-primary' onClick={()=>{}}>Update Profile</button>*/}
                                    </div>

                                </div>
                            </form>
                        </Col>

                        <Col md={6}>
                            <form className="auth-form" onSubmit={(e:any)=>submitPasswordUpdate(e)}>
                                <h4 className="form-set-header">Change Password</h4>
                                <hr/>
                                <div className="form-set">
                                    <div className={`form-group`}>
                                        <label>
                                            Current Password
                                        </label>
                                        <div className="auth-form-group">
                                            <input
                                                name="old"
                                                type={showPassword ? 'text' : 'password'}
                                                className="form-control input-ico-right"
                                                onChange={(e:any)=>onUpdatePw(e)}
                                                value={pwData.old}
                                            />
                                            <div className="ico-right"
                                                 onClick={() => toggleShowPassword(!showPassword)}>
                                                <i className={`fas ` + (showPassword ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`form-group`}>
                                        <label>
                                            New Password
                                        </label>
                                        <div className="auth-form-group">
                                            <input
                                                name="new1"
                                                type={showPassword1 ? 'text' : 'password'}
                                                className="form-control input-ico-right"
                                                onChange={(e:any)=>onUpdatePw(e)}
                                                value={pwData.new1}
                                            />
                                            <div className="ico-right"
                                                 onClick={() => toggleShowPassword1(!showPassword1)}>
                                                <i className={`fas ` + (showPassword1 ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`form-group`}>
                                        <label>
                                            Confirm New Password
                                        </label>
                                        <div className="auth-form-group">
                                            <input
                                                name="new2"
                                                type={showPassword2 ? 'text' : 'password'}
                                                className="form-control input-ico-right"
                                                onChange={(e:any)=>onUpdatePw(e)}
                                                value={pwData.new2}
                                            />
                                            <div className="ico-right"
                                                 onClick={() => toggleShowPassword2(!showPassword2)}>
                                                <i className={`fas ` + (showPassword2 ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`form-group`}>
                                        <br/>
                                        <button className='btn btn-primary'>Update Password</button>
                                        <br/>
                                        <Alert alertKey="pwUpdate"/>
                                    </div>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    profile: state.profile
});

export default connect(mapStateToProps, { setAlert, updatePassword })(MyProfile);

