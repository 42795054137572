import {ADD_TO_CART, REMOVE_FROM_CART} from '../types';

export const addToCart = ( accomod ) => (dispatch) => {
  const doc = {
    Key: accomod.Key,
    Title: accomod.Title,
    Subtitle: accomod.Subtitle,
    Description: accomod.Description,
    Considerations: accomod.Considerations,
    Examples: accomod.Examples,
    RelatedLinks: accomod.RelatedLinks,
    NeedsCategories: accomod.NeedsCategories,
    score: accomod.score,
    __i: accomod.__i
  };

  dispatch({
    type: ADD_TO_CART,
    payload: doc
  });
};


export const removeFromCart = ( accomod ) => (dispatch) => {
  const doc = {
    Key: accomod.Key
  };

  dispatch({
    type: REMOVE_FROM_CART,
    payload: doc
  });
};
