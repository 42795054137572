import React from 'react';
import Topbar from './topbar/Topbar';


const Layout = ( props:any ) => {

    const { userData } = props;

    return (
      <div className={`layout`}>
        <Topbar userData={userData} />
      </div>
    );

}

export default Layout;
