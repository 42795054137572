import { useEffect } from 'react';
import { useLocation } from 'react-router';

export default function useScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        let accoBody:any = document.getElementById("appRoot");
        if (accoBody) {
            accoBody.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [pathname]);
}
