import {CLOSE_MODAL, GET_TRAIT_DESCRIPTION, OPEN_MODAL} from "../types";
import axios from "axios";
import {apiURL} from "../../services/helpers";

export const openModal = ( accomodId, modalSize='large', fullData={ Key: '' } ) => (dispatch) => {
  dispatch({
    type: OPEN_MODAL,
    payload: { accomodId : accomodId, modalSize: modalSize, fullData: fullData }
  });
}

export const closeModal = () => (dispatch) => {
  dispatch({ type: CLOSE_MODAL  });
}

export const getTraitDescription = ( traitID, traitType = 'need') => async dispatch => {
  try {
    const q = "{ _id: '" + traitID + "' }";
    const res = await axios.get(apiURL()+'/NeedItem?action=get&i=0&size=1&q='+encodeURI(q));
    dispatch({
      type: GET_TRAIT_DESCRIPTION,
      payload: res.data.r[0]
    });

  } catch(err) {
    console.log('error loading needs');
  }
}


export const getAccomodDetails = ( accomodId ) => async dispatch => {

}

export const generatePdfUrl = ( html ) => async dispatch => {

}
