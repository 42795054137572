import {LOAD_NEEDS_CATS, LOAD_STRENGTH_CATS} from '../types';
import axios from "axios";
import {apiURL} from "../../services/helpers";

export const loadNeeds = () => async dispatch => {
  try {
    const res = await axios.get(apiURL()+'/NeedsParent?action=get&i=0&size=10');
    dispatch({
      type: LOAD_NEEDS_CATS,
      payload: res.data.r
    });
  } catch(err) {
    console.log('error loading needs');
  }
}


export const loadStrengths = () => async dispatch => {
  try {
    const res = await axios.get(apiURL()+'/StrengthsParent?action=get&i=0&size=10');
    dispatch({
      type: LOAD_STRENGTH_CATS,
      payload: res.data.r
    });
  } catch(err) {
    console.log('error loading strengths');
  }
}

