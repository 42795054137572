import {
    ADD_NEED,
    ADD_STRENGTH,
    CLEAR_BUILDER,
    GET_ACCOMODS,
    REMOVE_NEED,
    REMOVE_STRENGTH,
    SET_NEED_PRIORITY
} from '../types';

const initialState: any = {
    needs: [],
    strengths: [],
    accomodsLoading: true,
    accomods: []
};

export default function (state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {
        case ADD_NEED:
            return {
                ...state,
                needs: [...state.needs, payload],
                accomodsLoading: true,
                accomods: []
            }
        case REMOVE_NEED:
            return {
                ...state,
                needs: state.needs.filter((item: any) => payload.__i.guid !== item.__i.guid),
                accomodsLoading: true,
                accomods: []
            }
        case ADD_STRENGTH:
            return {
                ...state,
                strengths: [...state.strengths, payload],
                accomodsLoading: true,
                accomods: []
            }
        case REMOVE_STRENGTH:
            return {
                ...state,
                strengths: state.strengths.filter((item: any) => payload.__i.guid !== item.__i.guid),
                accomodsLoading: true,
                accomods: []
            }
        case SET_NEED_PRIORITY:
            return {
                ...state,
                needs: payload,
                accomodsLoading: true,
                accomods: []
            }
        case GET_ACCOMODS:
            return {
                ...state,
                accomodsLoading: false,
                accomods: payload
            }
        case CLEAR_BUILDER:
            return {
                needs: [],
                strengths: [],
                accomodsLoading: true,
                accomods: []
            }
        default:
            return state;
    }
}
