import {
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    SET_DISTRICT,
    USER_LOADED
} from '../types';

const initialState = {
    access_token: localStorage.token,
    isAuthenticated: false,
    loading: true,
    user: null,
    userName: localStorage.userName,
    userId: localStorage.userId,
    district: {
        name: null,
        code: null
    }
};

export default function( state = initialState, action:any ) {
    const { type, payload } = action;

    switch(type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                access_token: localStorage.token,
                userName: localStorage.userName,
                userId: localStorage.userId,
            };

        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            //console.log('setting stored token', payload.access_token);
            localStorage.setItem( 'token', payload.access_token );
            localStorage.setItem( 'userName', payload.userName );
            localStorage.setItem( 'userId', payload.userId );
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false
            };

        case SET_DISTRICT:
            return {
                ...state,
                district: payload
            }

        case REGISTER_FAIL:
        case LOGIN_FAIL:
        case AUTH_ERROR:
        case LOGOUT:
            //console.log('removing stored token');
            localStorage.removeItem('token');
            localStorage.removeItem('userName');
            localStorage.removeItem('userId');
            localStorage.removeItem('district');
            return {
                ...state,
                access_token: null,
                user: null,
                isAuthenticated: false,
                loading: false,
                token_type: null,
                expires_in: null,
                userName: null,
                userId: null,
                organization: null,
                isCustomer: null
            };

        default: return state;
    }
}
