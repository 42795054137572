import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PageTitle from '../../components/PageTitle';
import BuilderNav from './BuilderNav';
import kids2 from '../../shared/img/kids2.png';
import ExportShare from '../Cart/ExportShare';

const BuilderExport = (props: any) => {


    return (
        <>
            <PageTitle title='Accomods Builder' browse={true} search={true}/>

            <Container>
                <Row>
                    <Col sm={12}>
                        <div className='acco-builder'>
                            <div className='acco-builder-nav'>
                                <BuilderNav step={5}/>
                                <img src={kids2} alt="students" className=""/>
                            </div>
                            <div className='acco-builder-body'>
                                <ExportShare />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>


        </>
    );
};

export default BuilderExport;
