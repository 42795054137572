import { ADD_TO_CART, CLEAR_CART, REMOVE_FROM_CART } from '../types';

const initialState:any = {
    items: [],
};

export default function(state = initialState, action:any) {
    const { type, payload } = action;

    switch(type) {
        case ADD_TO_CART:
            return {
                ...state,
                items: [ ...state.items, payload ]
            }
        case REMOVE_FROM_CART:
            return {
                ...state,
                items: state.items.filter((item:any) => payload.Key !== item.Key)
            }
        case CLEAR_CART:
            return {
                items: [],
            }
        default:
            return state;
    }
}
