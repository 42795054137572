import { CLEAR_SEARCH, SEARCH_ACCOMODS, SEARCH_LOADING } from '../types';

const initialState: any = {
    isLoading: null,
    searchResults: []
};

export default function (state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {

        case SEARCH_ACCOMODS:
            return {
                ...state,
                searchResults: payload,
                isLoading: false
            };

        case SEARCH_LOADING:
            return {
                ...state,
                isLoading: payload
            }

        case CLEAR_SEARCH:
            return {
                isLoading: null,
                searchResults: []
            }

        default:
            return state;
    }
}
