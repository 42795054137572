import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './routing/Router';

// REDUX
import { Provider } from 'react-redux';
import store from './store.js';

const App = () => {
  return (
      <Provider store={store}>
        <BrowserRouter>
          <Router/>
        </BrowserRouter>
      </Provider>
  );
}


export default App;
