import React from 'react';

import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const GoToLogin = ( props:any ) => {
    return (
        <Navigate to={`/`} />
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(GoToLogin);

