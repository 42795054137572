import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import BuilderNav from './BuilderNav';
import kids2 from '../../shared/img/kids2.png';
import { connect } from 'react-redux';
import { addStrength, removeStrength } from '../../redux/actions/builder';

const BuilderStrengths = (props: any) => {

    const { data, builder, addStrength, removeStrength } = props;

    const defaultCustomStrengths = [
        { Name: '', __i: { guid: 'cs000' }},
        { Name: '', __i: { guid: 'cs001' }},
        { Name: '', __i: { guid: 'cs002' }},
        { Name: '', __i: { guid: 'cs003' }},
        { Name: '', __i: { guid: 'cs004' }},
        { Name: '', __i: { guid: 'cs005' }}
        ];

    if (builder && builder.strengths) {
        builder.strengths.forEach((cs:any)=>{
            if (cs.__i.guid.includes('cs00')) {
                let thisId = cs.__i.guid.substr(4,1);
                defaultCustomStrengths[thisId].Name = cs.Name;
            }
        });
    }

    const [customStrengths, setCustomStrengths] = useState( defaultCustomStrengths );

    const toggleStrength = ( strength:any ) => {
        if (isInStrengths(strength)) {
            removeStrength(strength);
        } else {
            addStrength(strength);
        }
    }

    const isInStrengths = ( strength:any ) => {
        let isChecked = false;
        builder.strengths.map( (s:any) => {
            //console.log( s.id, strength.__i.guid );
            if ( s.__i.guid === strength.__i.guid ) {
                isChecked = true;
            }
        });
        return isChecked;
    }

    const setCustomStrength = ( i:number, value:string ) => {
        let cs = [ ...customStrengths ];
        cs[i].Name = value;
        setCustomStrengths( cs );
    }

    const submitCustomStrengths = () => {
        customStrengths.map( (cs:any, i:number) => {
            removeStrength(cs);
            if (cs.Name.length) {
                addStrength(cs);
            }
        });
    }


    const builderStrengths = data && data.strengths && data.strengths.length ? data.strengths : [];

    return (
        <>
        <PageTitle title='Accomods Builder' browse={true} search={true} />

        <Container>
            <Row>
                <Col sm={12}>
                    <div className='acco-builder'>
                        <div className='acco-builder-nav'>
                            <BuilderNav step={1} />
                            <img src={kids2} alt="students" className="" />
                        </div>
                        <div className='acco-builder-body'>
                            <h2 className='green'>Identify your student's strengths:</h2>
                            <p>When you think about this student, what do you think are his/her strengths that we can
                                leverage to build our custom Accomods plan around? </p>

                            { builderStrengths.map( (s:any, i:number) =>
                                    <div className='acco-select-block' key={s.__i.guid}>
                                        <h3 className='acco-select-block-title blue'>{ s.Name }</h3>
                                        <div className='acco-select-block-flex'>
                                            { s.Strengths.map( (t:any, j:any) =>
                                                <div key={t.__i.guid}>
                                                    <label>
                                                        <input type='checkbox' checked={isInStrengths(t)} onChange={()=>toggleStrength(t)} />
                                                        <span>{ t.Name }</span>
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                            )}
                            <div className='acco-select-block'>
                                <h3 className='acco-select-block-title blue'>Add Your Own Strengths</h3>
                                <div className='acco-select-block-flex'>
                                    { customStrengths.map( (cs:any, i:number ) =>
                                        <div key={cs.__i.guid}>
                                            <label>
                                                <input type='checkbox' checked={cs.Name.length > 0}/>
                                                <span>
                                                    <input type='text'
                                                           className="form-control"
                                                           value={cs.Name}
                                                           onChange={(e:any)=>setCustomStrength(i, e.target.value)}
                                                           onBlur={(e:any)=>submitCustomStrengths()} />
                                                </span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='acco-builder-flowbtn'>
                            <Link to='/builder/needs' className={'btn btn-pill btn-lg btn-primary'}>
                                Next Step <i className="far fa-angle-double-right fa-lg"></i>
                            </Link>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    builder: state.builder,
    data: state.data,
});

export default connect( mapStateToProps, { addStrength, removeStrength })(BuilderStrengths);
