import {SEARCH_ACCOMODS, SEARCH_LOADING} from '../types';
import {apiURL} from "../../services/helpers";

import axios from "axios";

export const searchAccomods = ( searchQuery ) => async dispatch => {

  try {
    dispatch({ type: SEARCH_LOADING, payload: true });

    const q = "{ $text: { $search: '"+searchQuery+"' } }";
    const res = await axios.get(apiURL()+'/Accomods?action=get&size=250&q='+encodeURI(q));

    dispatch({
      type: SEARCH_ACCOMODS,
      payload: res.data.r
    });

  } catch (err) {
    console.log('error loading Accomods category');
  }

}
