import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import PageTitle from '../../components/PageTitle';
import { Col, Container, Row } from 'reactstrap';
import Topbar from '../Layout/topbar/Topbar';

const RegisterIndividual = (props: any) => {

    const { isAuthenticated } = props;

    const theURL:string = document.location.href;
    let thriveUrl:string = 'https://action-driven-education.thrivecart.com/single-user-accomods-subscription/';

    if ( theURL.includes('localhost') || theURL.includes('dev.accomods.com') ) {
        thriveUrl = 'https://action-driven-education.thrivecart.com/single-user-accomods-subscription-test/';
    }

    // Redirect if already signed in
    if (isAuthenticated) {
        return <Navigate to="/dashboard"/>
    }

    return (
        <>
            <Topbar />

            <div className={'acco-register'}>

                <PageTitle title='Create An Individual Account'
                           subtitle={'Individual accounts are for parents or teachers of students with special learning needs interested in tailoring individualized education programs.'}/>

                <Container>
                    <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                            <h4 className='accoAccess'>Get access to Accomods for one year for just $39.95.</h4>
                            <p>Your $39.95 purchase provides you with a one-year subscription to Accomods: hundreds of detailed accommodations and modifications designed to support the development and implementation of an effective education for students with special needs. Accomods empowers children with special needs past their disabilities!<br />&nbsp;</p>
                            <h3>Ready to get started?</h3>
                            <p>Click the button below to be redirected to our ThriveCart subscription management system. After you complete your registration and payment, you will be redirected back to Accomods to set your account password.</p>
                            <p style={{textAlign:'center'}}>
                                <a href={ thriveUrl } target="_top" className="btn btn-primary"
                                   style={{zoom:1.5,fontWeight:'bold',paddingLeft:'30px',paddingRight:'30px'}}>Continue to Registration</a>
                            </p>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { })(RegisterIndividual);

