import { CLEAR_DATA, LOAD_NEEDS_CATS, LOAD_STRENGTH_CATS } from '../types';

const initialState:any = {
    needs: [],
    strengths: []
};

export default function(state = initialState, action:any) {
    const { type, payload } = action;

    switch(type) {
        case LOAD_NEEDS_CATS:
            return {
                ...state,
                needs: payload
            }
        case LOAD_STRENGTH_CATS:
            return {
                ...state,
                strengths: payload
            }
        case CLEAR_DATA:
            return {
                needs: [],
                strengths: []
            }
        default:
            return state;
    }
}
